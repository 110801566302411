// ThemeToggle.jsx
import React, { useState, useEffect } from 'react';

const ThemeToggle = () => {
  // Define the themes
  const themes = {
    light: 'theme-light',
    dark: 'theme-dark',
  };

  // State to manage the current theme
  const [theme, setTheme] = useState(themes.light);

  // State to manage the toggle button's inner content (SVG)
  const [toggleIcon, setToggleIcon] = useState(null);

  // SVG Components
  const SunIcon = (
    <svg
      viewBox="0 0 24 24"
      className="Sun_Icon"
      width="24"
      height="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12 6a1 1 0 0 0 1-1V3a1 1 0 0 0-2 0v2a1 1 0 0 0 1 1zm9 5h-2a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2zM6 12a1 1 0 0 0-1-1H3a1 1 0 0 0 0 2h2a1 1 0 0 0 1-1zm.22-7a1 1 0 0 0-1.39 1.47l1.44 1.39a1 1 0 0 0 .73.28 1 1 0 0 0 .72-.31 1 1 0 0 0 0-1.41zM17 8.14a1 1 0 0 0 .69-.28l1.44-1.39A1 1 0 0 0 17.78 5l-1.44 1.42a1 1 0 0 0 0 1.41 1 1 0 0 0 .66.31zM12 18a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-2a1 1 0 0 0-1-1zm5.73-1.86a1 1 0 0 0-1.39 1.44L17.78 19a1 1 0 0 0 .69.28 1 1 0 0 0 .72-.3 1 1 0 0 0 0-1.42zm-11.46 0l-1.44 1.39a1 1 0 0 0 0 1.42 1 1 0 0 0 .72.3 1 1 0 0 0 .67-.25l1.44-1.39a1 1 0 0 0-1.39-1.44zM12 8a4 4 0 1 0 4 4 4 4 0 0 0-4-4z"></path>
    </svg>
  );

  const MoonIcon = (
    <svg
      viewBox="0 0 24 24"
      className="Moon_Icon"
      width="24"
      height="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.3 22h-.1a10.31 10.31 0 0 1-7.34-3.15 10.46 10.46 0 0 1-.26-14 10.13 10.13 0 0 1 4-2.74 1 1 0 0 1 1.06.22 1 1 0 0 1 .24 1 8.4 8.4 0 0 0 1.94 8.81 8.47 8.47 0 0 0 8.83 1.94 1 1 0 0 1 1.27 1.29A10.16 10.16 0 0 1 19.6 19a10.28 10.28 0 0 1-7.3 3z"></path>
    </svg>
  );

  // Effect to initialize theme on component mount
  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme && (storedTheme === themes.light || storedTheme === themes.dark)) {
      setTheme(storedTheme);
    } else {
      // Default to dark theme if no preference is stored
      setTheme(themes.dark);
    }
  }, []);

  // Effect to apply theme and set toggle icon whenever theme changes
  useEffect(() => {
    // Apply the theme by setting the class on the document's root element
    document.documentElement.className = theme;

    // Store the current theme in localStorage
    localStorage.setItem('theme', theme);

    // Set the toggle button's icon based on the current theme
    if (theme === themes.light) {
      setToggleIcon(MoonIcon); // Show Moon icon when in light mode (to switch to dark)
    } else {
      setToggleIcon(SunIcon); // Show Sun icon when in dark mode (to switch to light)
    }
  }, [theme]);

  // Function to toggle between themes
  const toggleTheme = () => {
    setTheme((prevTheme) =>
      prevTheme === themes.light ? themes.dark : themes.light
    );
  };

  return (
    <div 
        className='navbar-item themetoggle' 
        onClick={toggleTheme} aria-label="Toggle theme"
        aria-pressed={theme === themes.dark}>
        <button
            // onClick={toggleTheme}
            aria-label="Toggle theme"
            aria-pressed={theme === themes.dark}
            id="switch"
            style={{
                background: 'none',
                border: 'none',
                cursor: 'pointer',
                padding: '0',
                margin: '0',
                // display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            {toggleIcon}
        </button>
    </div>
  );
};

export default ThemeToggle;